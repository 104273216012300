import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {timestampToDate} from '../../../api/testingHistory.service'

const useStyles = makeStyles((theme) => ({
    root: {
      marginBottom: "30px",
      border: "1px solid #2278CF",
      borderRadius: "10px",
      '&:first-of-type': {
        borderTopLeftRadius: "10px",
        borderTopRightRadius: "10px",
      },
      '&:last-of-type': {
        borderBottomLeftRadius: "10px",
        borderBottomRightRadius: "10px",
    }  
    },
    accordion: {
        marginBottom: "20px",
        position: "relative",
        '&::before': {
            display: "block",
            position: "absolute",
            bottom: 0,
            left: "10%",
            content: `''`,
            width: "80%",
            height: "1px",
            backgroundColor: "#D1D5D8",
        },
        '&:last-child': {
            '&::before': {
                display: "none"
            }
        }
    },
    detail: {
        marginLeft: "20px",
    },
    row: {
        display: "flex",
        alignItems: "center",
        paddingBottom: "10px",
    },
    title: {
        paddingRight: "10px"
    }
  }));

function TestCard({data, topicName}) {
    const classes = useStyles();
    return (
       <Accordion className={classes.root}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h5" color="primary">
                    {`${topicName} - Quiz`}
                </Typography>
            </AccordionSummary>
            {data.map((testingRecord,index) => {
                return(
                    <AccordionDetails key ={index} className={classes.accordion}>
                        <div className={classes.detail}>
                            <div className={classes.row}>
                                <Typography variant="h5" color="textSecondary" className={classes.title}> Thời gian làm bài: </Typography>
                                <Typography>{timestampToDate(testingRecord.timestamp)}</Typography>
                            </div>
                            <div className={classes.row}>
                                <Typography variant="h5" color="textSecondary" className={classes.title}> Tổng điểm: </Typography>
                                <Typography>{testingRecord.correctAnswers}/{testingRecord.total}</Typography> 
                            </div>
                            <div className={classes.row}>
                                <Typography variant="h5" color="textSecondary" className={classes.title}> Thời gian hoàn thành: </Typography>
                                <Typography>{testingRecord.time}</Typography> 
                            </div>
                        </div>
                    </AccordionDetails>
                )
            })}
      </Accordion>
    )
}

export default TestCard
