import { getUser } from "../utils/auth"
import LearnedLesson from "../models/LearnedLesson"
// import { LESSON_TYPE } from "../constants/LessonType"
import getFirebase, { getCollectionByName } from "../utils/firebase"

const firebase = getFirebase()
const learningProgressCollection = getCollectionByName("learningProgress")

export function loadLearningProgress() {
  const allTopics = getUser().allTopics

  return firebase
    ? learningProgressCollection
        .where("uid", "==", getUser().uid)
        .get()
        .then(querySnapshot => {
          const learnedLessons = []

          querySnapshot.forEach(doc => {
            learnedLessons.push({
              ...doc.data(),
              id: doc.id,
            })
          })

          return createLearnedTopicsFromLessons(allTopics, learnedLessons)
        })
    : null
}

export function addOrUpdateLearningProgressItem(
  lessonId,
  topicId,
  type,
  point,
  total
) {
  const learnedLesson = new LearnedLesson()

  learnedLesson.id = lessonId
  learnedLesson.uid = firebase ? firebase.auth().currentUser.uid : null
  learnedLesson.topicId = topicId
  if (type === "quiz") {
    learnedLesson.type = type
    learnedLesson.point = point
    learnedLesson.total = total
  }

  return firebase
    ? learningProgressCollection
        .doc(lessonId)
        .set(Object.assign({}, learnedLesson))
    : null
}

function createLearnedTopicsFromLessons(allTopics, learnedLessons) {
  var learnedTopics = []
  let learnedTopicIds = []

  learnedLessons.forEach(lesson => {
    if (learnedTopicIds.indexOf(lesson.topicId) === -1) {
      learnedTopicIds.push(lesson.topicId)
    }
  })

  learnedTopics = allTopics
    ? allTopics.filter(item => learnedTopicIds.includes(item.id))
    : null

  learnedTopics &&
    learnedTopics.map(learnedTopic => {
      const learnedLessonCount = learnedLessons.filter(
        lesson =>
          lesson.topicId === learnedTopic.id && lesson.type === undefined
      ).length
      const finishedQuiz = learnedLessons.find(
        lesson => lesson.topicId === learnedTopic.id && lesson.type === "quiz"
      )

      learnedTopic.progress =parseFloat((learnedLessonCount * 100) / learnedTopic.lessonCount).toFixed(2)
      learnedTopic.percentage = `${learnedTopic.progress}%`

      if (finishedQuiz) {
        learnedTopic.rating = (finishedQuiz.point * 5) / finishedQuiz.total
      }

      return learnedTopic
    })

  return learnedTopics
}
