import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ALink from "../../common/ALink";

const useStyles = makeStyles((theme) => ({
  root: {
    "@media screen and (max-width: 767px)": {
      paddingLeft: "0px",
      paddingRight: "0px",
    },
  },
  title: {
    margin: "10px 0 30px 0",
  },
  newsLink: {
    margin: "0 0.5rem",
    backgroundColor: "transparent",
    width: "100%",
    textDecoration: "none",
    transition: "all 0.35s",
    "&:hover, &:focus": {
      textDecoration: "none",
      backgroundColor: "#F5F5F6",
      //   fontWeight: "600",
      color: "#FCE313",
    },
  },
  newsName: {
    color: "#1E88E5",
    fontWeight: "inherit",
    paddingLeft: theme.spacing(1),
  },
}));


function LikedBlog({ data }) {
  const classes = useStyles();

  return (
    <>
      <Typography
        className={classes.title}
        variant="h5"
        align="center"
        color="primary"
      >
        Các bài viết đã thích
      </Typography>

      <List>
        {data &&
          data.map((item, index) => {
            return (
              <ListItem key={index}>
                <ALink
                  to={`/blog/${item.id}`}
                  className={classes.newsLink}
                >
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography
                        variant="h6"
                        component="h3"
                        className={classes.newsName}
                      >
                        {item.news}
                      </Typography>
                    }
                  />
                </ALink>
              </ListItem>
            );
          })}
      </List>
    </>
  );
}

export default LikedBlog;
