import getFirebase, { getCollectionByName } from "../utils/firebase"
import ViewCounter from "../models/Blog"
import { getUser } from "../utils/auth"

const viewCounterCollection = getCollectionByName("ViewCounter")

export function getViewCountByBlogNewsId(id) {
  return viewCounterCollection
    .doc(id)
    .get()
    .then(doc => {
      if (doc.exists) {
        return doc.data()
      } else {
        const newViewCounter = new ViewCounter()
        newViewCounter.count = 0
        newViewCounter.likeUser = []
        return newViewCounter
      }
    })
}

export function addOrUpdateViewCounter( news, id, count, likeUser) {
  const newViewCounter = new ViewCounter()
  newViewCounter.news = news
  newViewCounter.id = id
  newViewCounter.count = count
  newViewCounter.likeUser = likeUser

  return viewCounterCollection.doc(id).set(Object.assign({}, newViewCounter))
}

export function getLikedBlogByUser() {
    return viewCounterCollection.get()
      .then((querySnapshot) => {
        var likedBlog = [];
        querySnapshot.forEach((doc) => {
          if (doc.data().likeUser.indexOf(getUser().uid) !== -1) {
            likedBlog.push({
                news: doc.data().news,
                id: doc.id
            })
        }
        });
        return likedBlog;
      });
  }