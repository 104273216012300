import React from 'react'
import TestCard from './TestCard'
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
    title: {
        margin: "20px 0 30px 0"
    },
  }))

function TestResult({ data }) {
    const classes = useStyles()
    return (
        <div>
            <Typography
                className={classes.title}
                variant="h5"
                align="center"
                color="primary"
            >
                Lịch sử kiểm tra
            </Typography>
                {data && data.map(item => <TestCard key={item.id} data={item.testingRecords} topicName={item.topicName} />)}
        </div>
    )
}

export default TestResult
