import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Container from '@material-ui/core/Container';
import Typography from "@material-ui/core/Typography";
import Box from '@material-ui/core/Box';
import useMediaQuery from "@material-ui/core/useMediaQuery";

import TopicCard from './TopicCard'

const useStyles = makeStyles(theme => ({
    title: {
        margin: "20px 0 30px 0"
    },
  }))

const MyTopic = ({data}) => {
    const classes = useStyles()
    const tabletViewport = useMediaQuery("(min-width:768px)");
    return(
        <Container disableGutters = {tabletViewport ? false : true}>
            <Typography className={classes.title} variant="h5" align="center" color="primary">
                My Topics
            </Typography>
            <Box>
                {
                    data && data.map((item, index) => {
                        return <div key={index}>
                            <TopicCard data={item} />
                        </div>
                    })
                }
            </Box>
        </Container>
    )
}
export default MyTopic