import React, {useState, useEffect} from 'react';
import { Redirect } from "react-router-dom";
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import ListAltIcon from '@material-ui/icons/ListAlt';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Paper } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import UserInformation from "../sections/UserInformation"
import MyTopic from "../sections/MyTopic"
import TestResult from "../sections/TestResult"
import { isLoggedIn } from "../../../utils/auth";
import { loadingUserInfo } from "../../../api/user.service";
import { loadLearningProgress } from "../../../api/learningProgress.service";
import { loadTestingRecords } from "../../../api/testingHistory.service";
import {getLikedBlogByUser} from "../../../api/blog.service";
import LikeBlog from "../sections/LikeBlog"

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  }
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: "20px 20px",
    [theme.breakpoints.up("md")]: {
      padding: "40px 40px",
    },
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    [theme.breakpoints.up("md")]: {
      display: "flex",
      flexDirection: "column",
      width: "30%",
    },
  },
  paper: {
    borderRadius: "10px",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  tabPanel: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "70%",
    },
  },
}))

const User = () => {
  const classes = useStyles()
  const [value, setValue] = React.useState(0)
  const [userInfo, setUserInfo] = useState()
  const [learnedTopics, setLearnedTopics] = useState()
  const [testingRecords, setTestingRecords] = useState()
  const [reloadingUserInfo, setReloadingUserInfo] = useState(true)
  const desktopViewport = useMediaQuery("(min-width:1024px)")
  const tabletViewport = useMediaQuery("(min-width:768px)")
  const [likeBlog, setLikedBlog] = useState("")

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  useEffect(() => {
    loadingUserInfo().then(result => {
      setUserInfo(result)
      setReloadingUserInfo(false)
    })
  }, [reloadingUserInfo])

  useEffect(() => {
    getLikedBlogByUser().then(res => {
      setLikedBlog(res)
    })
    loadLearningProgress().then(res => {
      setLearnedTopics(res)
    })
    loadTestingRecords().then(res => {
      setTestingRecords(res)
    })
  }, [])

  // if (!isLoggedIn()) {
  //   return <Redirect to="/" />
  // }

  return (
    <Container className={classes.root}>
      <Paper elevation={3} classes={{ root: classes.paper }}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          className={classes.tabs}
          orientation={desktopViewport ? "vertical" : "horizontal"}
        >
          <Tab
            classes={{ root: classes.tab }}
            icon={<PersonPinIcon />}
            {...a11yProps(0)}
            label={tabletViewport ? "Thông tin tài khoản" : ""}
          />
          <Tab
            classes={{ root: classes.tab }}
            icon={<ListAltIcon />}
            {...a11yProps(1)}
            label={tabletViewport ? "Lịch sử bài học" : ""}
          />
          <Tab
            classes={{ root: classes.tab }}
            icon={<PlaylistAddCheckIcon />}
            {...a11yProps(2)}
            label={tabletViewport ? "Lịch sử bài thi" : ""}
          />
          <Tab
            classes={{ root: classes.tab }}
            icon={<PlaylistAddCheckIcon />}
            {...a11yProps(3)}
            label={tabletViewport ? "Bài viết yêu thích" : ""}
          />
        </Tabs>

        <TabPanel value={value} index={0} className={classes.tabPanel}>
          {userInfo && (
            <UserInformation
              needsReloading={setReloadingUserInfo}
              data={userInfo}
            />
          )}
        </TabPanel>
        <TabPanel value={value} index={1} className={classes.tabPanel}>
          {learnedTopics && <MyTopic data={learnedTopics} />}
        </TabPanel>
        <TabPanel value={value} index={2} className={classes.tabPanel}>
          {testingRecords && <TestResult data={testingRecords} />}
        </TabPanel>
        <TabPanel value={value} index={3} className={classes.tabPanel}>
          {likeBlog && <LikeBlog data={likeBlog} />}
        </TabPanel>
      </Paper>
    </Container>
  )
}
export default User
