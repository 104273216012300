import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import Container from '@material-ui/core/Container';
import {  Avatar, TextField } from '@material-ui/core'
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { useFormik } from 'formik';
import * as yup from 'yup';
import { addOrUpdateUserInfo } from "../../../api/user.service";

const useStyles = makeStyles(theme => ({
  root: {
    margin: "auto",
    maxWidth: "100%",
    padding: "0px ",
  },
  conten: {
    width: "100%",
    marginLeft: "0",
    [theme.breakpoints.up("sm")]: {
      marginLeft: "25%",
    },
  },
  row: {
    marginBottom: "40px",
  },
  title: {
    padding: "10px 20px 0 0",
  },
  avatar: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "20px",
    "& div": {
      width: theme.spacing(12),
      height: theme.spacing(12),
      [theme.breakpoints.up("lg")]: {
        width: theme.spacing(20),
        height: theme.spacing(20),
      },
    },
  },
  avatarImage: {
    position: "relative",
  },
  avatarCamera: {
    position: "absolute",
    top: "35%",
    right: "34%",
    fontSize: "40px",
    [theme.breakpoints.up("sm")]: {
      maxTop: "21%",
      right: "44%",
    },
    [theme.breakpoints.up("lg")]: {
      top: "37%",
      right: "44%",
    },
  },
  text: {
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      maxWidth: "50%",
    },
  },
  button: {
    display: "flex",
    justifyContent: "center",
  },
  buttonItem: {
    margin: "0 10px",
    width: "120px",
    [theme.breakpoints.up("sm")]: {
      margin: "0 20px",
    },
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  radio: {
    display: "block",
  },
}))

// check Validation
const now = new Date()
const yesterday = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1)
const birthDay = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1)

const phoneNumberRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const validationSchema = yup.object({
  displayName: yup.string().required("Hãy nhập tên của bạn"),
  phoneNumber: yup
    .string()
    .matches(phoneNumberRegExp, "Số điện thoại không đúng")
    .min(10, "Số điện thoại không đúng")
    .max(13, "Số điện thoại không đúng")
    .required("Hãy nhập số điện thoại"),
  birthday: yup
    .date()
    .typeError("Hãy nhập ngày tháng năm sinh của bạn")
    .max(yesterday, "Bạn không thể nhập ngày sinh vào hôm nay hoặc tương lai"),
})

const EditInformation = ({ data, onCancelEdittingData, onAfterSavedData }) => {
  const classes = useStyles()

  const formik = useFormik({
    initialValues: {
      displayName: data.displayName,
      photoURL: data.photoURL,
      birthday: data.birthday || null,
      email: data.email,
      phoneNumber: data.phoneNumber || "",
      sex: data.sex || "",
      address: data.address || "",
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      addOrUpdateUserInfo(values).then(() => {
        onAfterSavedData(values)
      })
    },
  })
  const handleCancel = event => {
    onCancelEdittingData()
    event.preventDefault()
  }
  return (
    <React.Fragment>
      <Container>
        <form className={classes.root} onSubmit={formik.handleSubmit}>
          <Typography variant="h5" align="start" color="primary" paragraph>
            Sửa đổi thông tin
          </Typography>
          <div className={classes.avatar}>
            <Avatar
              alt={data.displayName}
              src={data.photoURL}
              className={classes.avatarImage}
            />
          </div>
          <div className={classes.conten}>
            <div className={classes.row}>
              <Typography variant="h6" gutterBottom className={classes.title}>
                Họ Tên:
              </Typography>
              <TextField
                value={formik.values.displayName}
                onChange={formik.handleChange}
                name="displayName"
                type="text"
                className={classes.text}
                error={
                  formik.touched.displayName &&
                  Boolean(formik.errors.displayName)
                }
                helperText={
                  formik.touched.displayName && formik.errors.displayName
                }
              />
            </div>

            <div className={classes.row}>
              <Typography variant="h6" gutterBottom className={classes.title}>
                Ngày Sinh:
              </Typography>

              <TextField
                name="birthday"
                type="date"
                defaultValue={birthDay.toISOString().slice(0, 10)}
                value={formik.values.birthday}
                onChange={formik.handleChange}
                error={
                  formik.touched.birthday && Boolean(formik.errors.birthday)
                }
                helperText={formik.touched.birthday && formik.errors.birthday}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>

            <div className={classes.row}>
              <FormControl component="fieldset">
                <Typography variant="h6" gutterBottom className={classes.title}>
                  Giới Tính:
                </Typography>
                <RadioGroup
                  className={classes.radio}
                  inputProps={{ style: { display: "none" } }}
                  aria-label="sex"
                  name="sex"
                  value={formik.values.sex}
                  onChange={formik.handleChange}
                >
                  <FormControlLabel value="Nữ" control={<Radio />} label="Nữ" />
                  <FormControlLabel
                    value="Nam"
                    control={<Radio />}
                    label="Nam"
                  />
                </RadioGroup>
              </FormControl>
            </div>

            <div className={classes.row}>
              <Typography variant="h6" gutterBottom className={classes.title}>
                Số Điện Thoại:
              </Typography>
              <TextField
                value={formik.values.phoneNumber}
                onChange={formik.handleChange}
                name="phoneNumber"
                error={
                  formik.touched.phoneNumber &&
                  Boolean(formik.errors.phoneNumber)
                }
                helperText={
                  formik.touched.phoneNumber && formik.errors.phoneNumber
                }
                className={classes.text}
              />
            </div>

            <div className={classes.row}>
              <Typography variant="h6" gutterBottom className={classes.title}>
                Email:
              </Typography>
              <Input
                placeholder={data.email}
                name="email"
                type="text"
                disabled
                disableUnderline
                className={classes.text}
              />
            </div>
            <div className={classes.row}>
              <Typography variant="h6" gutterBottom className={classes.title}>
                Địa Chỉ:
              </Typography>
              <TextField
                value={formik.values.address}
                onChange={formik.handleChange}
                name="address"
                type="text"
                error={formik.touched.address && Boolean(formik.errors.address)}
                helperText={formik.touched.address && formik.errors.address}
                className={classes.text}
              />
            </div>
          </div>

          <div className={classes.button}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleCancel}
              className={classes.buttonItem}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              color="primary"
              type="submit"
              className={classes.buttonItem}
            >
              Save
            </Button>
          </div>
        </form>
      </Container>
    </React.Fragment>
  )
}
export default EditInformation
