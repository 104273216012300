import React from "react"
import { Router } from "@reach/router"
import UserTemplate from "../template/UserTemplate"
import PrivateRoute from "../components/user/pageContents/privateRoute"

function UserPage() {
  return (
    <Router>
      <PrivateRoute path="/app/user" component={UserTemplate} />
    </Router>
  )
}

export default UserPage
