import React, { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import Container from "@material-ui/core/Container"
import EditIcon from "@material-ui/icons/Edit"
import IconButton from "@material-ui/core/IconButton"
import Avatar from "@material-ui/core/Avatar"
import EditInformation from "./EditInformation"

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    padding: "0",
  },
  title: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "10px",
    [theme.breakpoints.up("sm")]: {
      marginBottom: "30px",
    },
  },
  Container: {
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      justifyContent: "space-evenly",
      alignItems: "center",
    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
      justifyContent: "space-evenly",
      alignItems: "center",
    },
  },
  ContainerLeft: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "40%",
  },
  ContentRight: {
    width: "60%",
  },
  avatar: {
    marginBottom: "20px",
    "& div": {
      width: theme.spacing(12),
      height: theme.spacing(12),
      [theme.breakpoints.up("lg")]: {
        width: theme.spacing(20),
        height: theme.spacing(20),
      },
    },
  },
  name: {
    marginBottom: "20px",
  },

  row: {
    display: "flex",
    flexDirection: "column",
    paddingBottom: "20px",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
    },
    "& h5:first-of-type": {
      paddingBottom: "15px",
    },
  },
  item: {
    minWidth: "160px",
  },
  items: {
    width: "100%",
  },
  address: {
    marginLeft: "20px",
    minWidth: "80px",
  },
}))

const UserInformation = ({ data, needsReloading }) => {
  const classes = useStyles()
  const [isEditting, setIsEditting] = useState(false)
  const handleEditIconClick = event => {
    setIsEditting(true)
    event.preventDefault()
  }

  const handleAfterSavedData = () => {
    setIsEditting(false)
    needsReloading(true)
  }

  const handleCancelEdittingData = () => {
    setIsEditting(false)
  }

  return (
    <React.Fragment>
      {isEditting === false ? (
        <Container className={classes.root}>
          <div className={classes.title}>
            <Typography variant="h5" align="center" color="primary">
              Thông tin tài khoản
            </Typography>
            <IconButton onClick={handleEditIconClick}>
              <EditIcon color="primary" />
            </IconButton>
          </div>
          <div className={classes.Container}>
            <div className={classes.ContainerLeft}>
              <div className={classes.avatar}>
                <Avatar alt={data.displayName} src={data.photoURL} />
              </div>
              <div className={classes.name}>
                <Typography variant="h5" color="primary">
                  {data.displayName}
                </Typography>
              </div>
            </div>
            <div className={classes.ContentRight}>
              {data.birthday === "" || data.birthday === undefined ? null : (
                <div className={classes.row}>
                  <Typography variant="h5" className={classes.item}>
                    Ngày Sinh:
                  </Typography>
                  <Typography
                    variant="h5"
                    color="primary"
                    className={classes.items}
                  >
                    {data.birthday}
                  </Typography>
                </div>
              )}
              {data.email === "" || data.email === undefined ? null : (
                <div className={classes.row}>
                  <Typography variant="h5" className={classes.item}>
                    Email:
                  </Typography>
                  <Typography
                    variant="h5"
                    color="primary"
                    className={classes.items}
                  >
                    {data.email}
                  </Typography>
                </div>
              )}
              {data.sex === "" || data.sex === undefined ? null : (
                <div className={classes.row}>
                  <Typography variant="h5" className={classes.item}>
                    Giới Tính:
                  </Typography>
                  <Typography
                    variant="h5"
                    color="primary"
                    className={classes.items}
                  >
                    {data.sex}
                  </Typography>
                </div>
              )}
              {data.phoneNumber === "" ||
              data.phoneNumber === undefined ? null : (
                <div className={classes.row}>
                  <Typography variant="h5" className={classes.item}>
                    Số Điện Thoại:
                  </Typography>
                  <Typography
                    variant="h5"
                    color="primary"
                    className={classes.items}
                  >
                    {data.phoneNumber}
                  </Typography>
                </div>
              )}
              {data.address === "" || data.address === undefined ? null : (
                <div className={classes.row}>
                  <Typography variant="h5" className={classes.item}>
                    Địa Chỉ:
                  </Typography>
                  <Typography
                    variant="h5"
                    color="primary"
                    multiline={true}
                    className={classes.items}
                  >
                    {data.address}
                  </Typography>
                </div>
              )}
            </div>
          </div>
        </Container>
      ) : (
        <EditInformation
          data={data}
          onAfterSavedData={handleAfterSavedData}
          onCancelEdittingData={handleCancelEdittingData}
        />
      )}
    </React.Fragment>
  )
}
export default UserInformation
