import React from "react"
import User from "../components/user/pageContents/User"
import withRoot from "../components/shell/withRoot"

function UserTemplate() {
  return (
    <React.StrictMode>
      <User />
    </React.StrictMode>
  )
}

export default withRoot(UserTemplate)
